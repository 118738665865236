window.globalData = {};
(function (global) {
  var globalData = {};
  global.globalData = globalData;

  globalData.agenType= [
    'Corporate',
    'Leisure',
    'Consolidator',
    'Wholesale',
    'OTA',
    'Corporate + Leisure'
  ];

  globalData.gdsTechnology= [
    'Amadeus',
    'Apollo',
    'Axess',
    'Galileo',
    'Infini',
    'Sabre',
    'Sabre Asia Pacific',
    'Travelsky',
    'Worldspan'
  ];

  globalData.gdsTechOID= [ {
    gds: 'Amadeus',
    oidMinLength: 9,
    oidMaxLength: 9
  }, {
    gds: 'Apollo',
    oidMinLength: 3,
    oidMaxLength: 4
  }, {
    gds: 'Axess',
    oidMinLength: 3,
    oidMaxLength: 3
  }, {
    gds: 'Galileo',
    oidMinLength: 3,
    oidMaxLength: 4
  }, {
    gds: 'Sabre',
    oidMinLength: 4,
    oidMaxLength: 4
  }, {
    gds: 'Sabre Asia Pacific',
    oidMinLength: 4,
    oidMaxLength: 4
  }, {
    gds: 'Travelsky',
    oidMinLength: 6,
    oidMaxLength: 6
  }, {
    gds: 'Worldspan',
    oidMinLength: 3,
    oidMaxLength: 3
  } ];

  globalData.cityDropDown = [
    'Amadeus',
    'Apollo',
    'Axess',
    'Galileo',
    'Infini',
    'Sabre',
    'Sabre Asia Pacific',
    'Travelsky',
    'Worldspan'
  ];

  globalData.stateDropDown = [
    'Denver',
    'Dover',
    'Hartford',
    'Juneau',
    'Little Rock',
    'Montgomery',
    'Phoenix',
    'Sacramento'
  ];

  globalData.currencyDropDown = [
    'USD',
    'EUR',
    'VND',
  ];

  globalData.countries = [
    {
      countryCode: 'NZ',
      description: 'New Zealand',
      phoneCode: '061',
      id: 19,
      stations: [
        {
          description: 'Christchurch',
          stateCode: 'CHC',
          id: 8
        },
        {
          description: 'Auckland',
          stateCode: 'AKL',
          id: 7
        },
        {
          description: 'Wellington',
          stateCode: 'WLG',
          id: 9
        }
      ],
      states: [
        {
          description: 'Christchurch',
          stateCode: 'CHC',
          id: 8
        },
        {
          description: 'Auckland',
          stateCode: 'AKL',
          id: 7
        },
        {
          description: 'Wellington',
          stateCode: 'WLG',
          id: 9
        }
      ]
    },
    {
      countryCode: 'SG',
      description: 'Singapore',
      phoneCode: '065',
      id: 28,
      stations: [
        {
          description: 'Singapore',
          stateCode: 'SIN',
          id: 10
        }
      ],
      states: [
        {
          description: 'Singapore',
          stateCode: 'SIN',
          id: 10
        }
      ]
    },
    {
      countryCode: 'AU',
      description: 'Australia',
      phoneCode: '084',
      id: 46,
      stations: [
        {
          description: 'Adelaide',
          stateCode: 'ADL',
          id: 4
        },
        {
          description: 'Sydney',
          stateCode: 'SYD',
          id: 2
        },
        {
          description: 'Melbourne',
          stateCode: 'MEL',
          id: 1
        },
        {
          description: 'Canberra',
          stateCode: 'CBR',
          id: 6
        },
        {
          description: 'Brisbane',
          stateCode: 'BNE',
          id: 3
        },
        {
          description: 'Perth',
          stateCode: 'PER',
          id: 5
        }
      ],
      states: [
        {
          description: 'Adelaide',
          stateCode: 'ADL',
          id: 4
        },
        {
          description: 'Sydney',
          stateCode: 'SYD',
          id: 2
        },
        {
          description: 'Melbourne',
          stateCode: 'MEL',
          id: 1
        },
        {
          description: 'Canberra',
          stateCode: 'CBR',
          id: 6
        },
        {
          description: 'Brisbane',
          stateCode: 'BNE',
          id: 3
        },
        {
          description: 'Perth',
          stateCode: 'PER',
          id: 5
        }
      ]
    },
    {
      countryCode: 'CH',
      description: 'Switzerland',
      phoneCode: '058',
      id: 49,
      stations: [
        {
          description: 'Geneva',
          stateCode: 'GVA',
          id: 12
        },
        {
          description: 'Zurich',
          stateCode: 'ZRH',
          id: 11
        }
      ],
      states: [
        {
          description: 'Geneva',
          stateCode: 'GVA',
          id: 12
        },
        {
          description: 'Zurich',
          stateCode: 'ZRH',
          id: 11
        }
      ]
    },
    {
      countryCode: 'GB',
      description: 'United Kingdom',
      phoneCode: '045',
      id: 61,
      stations: [
        {
          description: 'London',
          stateCode: 'LON',
          id: 13
        },
        {
          description: 'Manchester',
          stateCode: 'MAN',
          id: 14
        }
      ],
      states: [
        {
          description: 'London',
          stateCode: 'LON',
          id: 13
        },
        {
          description: 'Manchester',
          stateCode: 'MAN',
          id: 14
        }
      ]
    },
    {
      countryCode: 'FR',
      description: 'France',
      phoneCode: '098',
      id: 78,
      stations: [
        {
          description: 'Paris',
          stateCode: 'PAR',
          id: 15
        }
      ],
      states: [
        {
          description: 'Paris',
          stateCode: 'PAR',
          id: 15
        }
      ]
    },
    {
      countryCode: 'DE',
      description: 'Germany',
      phoneCode: '049',
      id: 79,
      stations: [
        {
          description: 'Dusseldorf',
          stateCode: 'DUS',
          id: 18
        },
        {
          description: 'Munich',
          stateCode: 'MUC',
          id: 17
        },
        {
          description: 'Frankfurt',
          stateCode: 'FRA',
          id: 16
        }
      ],
      states: [
        {
          description: 'Dusseldorf',
          stateCode: 'DUS',
          id: 18
        },
        {
          description: 'Munich',
          stateCode: 'MUC',
          id: 17
        },
        {
          description: 'Frankfurt',
          stateCode: 'FRA',
          id: 16
        }
      ]
    },
    {
      countryCode: 'ES',
      description: 'Spain',
      phoneCode: '012',
      id: 80,
      stations: [
        {
          description: 'Madrid',
          stateCode: 'MAD',
          id: 20
        },
        {
          description: 'Barcelona',
          stateCode: 'BCN',
          id: 19
        }
      ],
      states: [
        {
          description: 'Madrid',
          stateCode: 'MAD',
          id: 20
        },
        {
          description: 'Barcelona',
          stateCode: 'BCN',
          id: 19
        }
      ]
    }
  ];

  globalData.iataData = {
    '12345678': {
      company: 'TravelWithUS',
      country: 'Australia',
      countryCode: 'SG',
      companyTrading: '',
      companyLegal: 'Company legal',
      addressLine1: 'Address 1',
      addressLine2: 'Address 2',
      city: 'Phoenix',
      state: 'Denver',
      postalCode: '10000',
      employees: 50,
      currency: 'USD',
      phoneCode: 'Australia (+84)'
    },
    '1234567': {
      company: 'Company',
      country: 'Singapore',
      companyTrading: '',
      companyLegal: 'Company legal',
      addressLine1: 'Test address 1',
      addressLine2: 'Test address 2',
      city: 'Little Rock',
      state: 'Sacramento',
      postalCode: '9000',
      employees: 100,
      currency: 'EUR',
      phoneCode: 'Singapore (+65)'
    },
  };
})(window);
